import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  systemSettings: null,
};

const setting = createSlice({
  name: 'SettingSlice',
  initialState,
  reducers: {
    setSystemSettingsData: (state, action) => {
      state.systemSettings = action.payload;
    },
  },
});

export const { setSystemSettingsData } = setting.actions;

export default setting.reducer;
