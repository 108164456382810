import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from 'src/views/authentication/auth/AuthGuard';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const BesiAdminManagement = Loadable(lazy(() => import('../views/besiadminmanagement/BesiAdminManagement')));
const AddBesiAdminManagement = Loadable(lazy(() => import('../views/besiadminmanagement/AddBesiAdminManagement')));
const FlowersManagement = Loadable(lazy(() => import('../views/flowers/FlowersManagement')));
const IllnessesManagement = Loadable(lazy(() => import('../views/illnesses/IllnessesManagement')));
const AddIllness = Loadable(lazy(() => import('../views/illnesses/AddIllness')));
const InventoryManagement = Loadable(lazy(() => import('../views/inventory/InventoryManagement')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const AuthorizationError = Loadable(lazy(() => import('../views/authentication/AuthorizationError')));
const Register = Loadable(lazy(() => import('../views/authentication/SignUp')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const ForgetPassword = Loadable(lazy(() => import('../views/authentication/ForgetPassword')));
const OtpConfirmation = Loadable(lazy(() => import('../views/authentication/OtpConfirmation')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));
const AddFlowerPage = Loadable(lazy(() => import('../views/flowers/AddFlowerPage')));
const FlowersBulkUpload = Loadable(lazy(() => import('../views/flowers/FlowersBulkUpload')));
const SellerBulkUpload = Loadable(lazy(() => import('../views/flowers/SellerBulkUpload')));
const IllnessBulkUpload = Loadable(lazy(() => import('../views/illnesses/IllnessBulkUpload')));
const AddInventoryPage = Loadable(lazy(() => import('../views/inventory/AddInventoryPage')));
const InventoryBulkUpload = Loadable(lazy(() => import('../views/inventory/InventoryBulkUpload')));
const TechnicalData = Loadable(lazy(() => import('../views/technicaldata/TechnicalData')));
const Settings = Loadable(lazy(() => import('../views/settings/Settings')));
const PromotionsManagement = Loadable(
  lazy(() => import('../views/promotionsandclaims/promotions/PromotionsManagement')),
);
const ClaimsManagement = Loadable(lazy(() => import('../views/promotionsandclaims/claims/ClaimsManagement')));
const CommentsManagement = Loadable(lazy(() => import('../views/comments/CommentsManagement')));

const Router = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/', element: <Navigate to="/admin-management" /> },
      { path: '/dashboard', exact: true, element: <Dashboard /> },
      { path: '/admin-management', exact: true, element: <BesiAdminManagement /> },
      { path: '/admin-management/add-admin', element: <AddBesiAdminManagement /> },
      { path: '/admin-management/edit-admin/:id', element: <AddBesiAdminManagement /> },
      { path: '/admin-management/bulk-upload', element: <SellerBulkUpload /> },
      { path: '/flowers-managment', exact: true, element: <FlowersManagement /> },
      { path: '/flowers-managment/flower/:id?', element: <AddFlowerPage /> },
      { path: '/flowers-managment/bulk-upload', element: <FlowersBulkUpload /> },
      { path: '/illnesses-management', exact: true, element: <IllnessesManagement /> },
      { path: '/illnesses-management/add-illness', exact: true, element: <AddIllness /> },
      { path: '/illnesses-management/edit-illness/:id', element: <AddIllness /> },
      { path: '/illnesses-management/bulk-upload', element: <IllnessBulkUpload /> },
      { path: '/illnesses-management', exact: true, element: <IllnessesManagement /> },
      { path: '/illnesses-management/illness/:id?', exact: true, element: <AddIllness /> },
      { path: '/promotions-management', exact: true, element: <PromotionsManagement /> },
      { path: '/claims-management', exact: true, element: <ClaimsManagement /> },
      { path: '/inventory-management', exact: true, element: <InventoryManagement /> },
      { path: '/inventory-management/inventory/:id?', element: <AddInventoryPage /> },
      { path: '/inventory-management/bulk-upload', element: <InventoryBulkUpload /> },
      { path: '/technical-data', exact: true, element: <TechnicalData /> },
      { path: '/settings', exact: true, element: <Settings /> },
      { path: '/comments-management', exact: true, element: <CommentsManagement /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/forgetpassword', element: <ForgetPassword /> },
      { path: '/auth/otpconfirmation', element: <OtpConfirmation /> },
      { path: '/auth/resetpassword', element: <ResetPassword /> },
    ],
  },
  { path: '*', element: <Error /> },
  {
    path: '/unauthorized',
    element: (
      <AuthGuard>
        <AuthorizationError />
      </AuthGuard>
    ),
  },
];

export default Router;
