import { errorToast } from 'src/shared/Toast';
import axios from 'axios';

export const errorMessageHandler = (responseData) => {
  let errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.forEach((e) => errorArray.push(e.message));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push('Something went wrong!');
  }
  errorArray.forEach((errorMessage) => {
    errorToast(errorMessage);
  });
};

export const handleCatchError = (error) => {
  if (!axios.isCancel(error)) {
    console.error('Error:', error);
    errorToast('Something went wrong!');
  }
};

export const downloadBlobData = (data, fileName) => {
  try {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    // Clean up after the download
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    handleCatchError(error);
  }
};

export const toSnakeCase = (string) => string?.replace(/([a-z])([A-Z])/g, '$1_$2')?.toLowerCase();
