import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorToast } from 'src/shared/Toast';
import { handleCatchError } from 'src/utils/helper';
import { setSystemSettingsData } from '../../../reducers/slices/SettingSlice';
import { getUserByToken } from 'src/ApiCalls/AdminApiCalls';
import HashLoaderBackdrop from 'src/shared/HashLoaderBackdrop';

function AuthGuard({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem('authToken');
  const authData = useSelector((state) => state.Auth.authData);
  const [loading, setLoading] = useState(true);

  const getSystemSetting = useCallback(() => {
    (async () => {
      if (accessToken) {
        try {
          const { data } = await axios.get('/settings');
          if (data?.status) {
            const reponseData = data?.data;
            const settingsData = reponseData.reduce((acc, setting) => {
              acc[setting.key] = setting.value;
              return acc;
            }, {});
            dispatch(setSystemSettingsData(settingsData));
          } else {
            errorToast(data?.message);
          }
        } catch (error) {
          handleCatchError(error);
        }
      }
    })();
  }, [dispatch, accessToken]);

  useEffect(() => {
    if (authData) {
      if (authData.type === 3) {
        navigate('/unauthorized');
      }
    }
  }, [authData, navigate]);

  useEffect(() => {
    getSystemSetting();
  }, [getSystemSetting]);

  useEffect(() => {
    (async () => {
      if (accessToken) {
        const isValidUser = await getUserByToken();
        if (isValidUser) setLoading(false);
      } else {
        localStorage.clear();
        navigate('/auth/login');
      }
    })();
  }, [accessToken, navigate]);
  return loading ? <HashLoaderBackdrop /> : children;
}

export default AuthGuard;
