import { combineReducers, configureStore } from '@reduxjs/toolkit';
import AlertsSlice from './slices/AlertsSlice';
import AuthSlice from './slices/AuthSLice';
import SettingSlice from './slices/SettingSlice';
import masterDataReducer from './slices/masterData';
import technicalData from './slices/technicalData';
const store = configureStore({
  reducer: combineReducers({
    Auth: AuthSlice,
    Alerts: AlertsSlice,
    technical: technicalData,
    masterData: masterDataReducer,
    settingData: SettingSlice,
  }),
});

export const { dispatch, getState } = store;

export default store;
