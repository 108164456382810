import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  aromatics: [],
  bestTimeToConsumes: [],
  genetics: [],
  illness: [],
  pzns: [],
  cannabinoids: [],
  medicalUses: [],
  terpenes: [],
  effects: [],
  countries: [],
  businessUsers: [],
  strains: [],
  plantTraits: [],
};

const masterDataSlice = createSlice({
  name: 'masterData',
  initialState,
  reducers: {
    setAromatics: (state, action) => {
      state.aromatics = action.payload;
    },
    setBestTimeToConsume: (state, action) => {
      state.bestTimeToConsumes = action.payload;
    },
    setGenetics: (state, action) => {
      state.genetics = action.payload;
    },
    setIllness: (state, action) => {
      state.illness = action.payload;
    },
    setPzn: (state, action) => {
      state.pzns = action.payload;
    },
    setCannabinoids: (state, action) => {
      state.cannabinoids = action.payload;
    },
    setMedicalUse: (state, action) => {
      state.medicalUses = action.payload;
    },
    setTerpenes: (state, action) => {
      state.terpenes = action.payload;
    },
    setEffects: (state, action) => {
      state.effects = action.payload;
    },
    setBusinessUsers: (state, action) => {
      state.businessUsers = action.payload;
    },
    setStrains: (state, action) => {
      state.strains = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setPlantsTraits: (state, action) => {
      state.plantTraits = action.payload;
    },
    clearAllMasterData: () => initialState,
  },
});

export const {
  setAromatics,
  setBestTimeToConsume,
  setGenetics,
  setIllness,
  setPzn,
  setCannabinoids,
  setMedicalUse,
  setTerpenes,
  setEffects,
  setBusinessUsers,
  setStrains,
  setCountries,
  setPlantsTraits,
  clearAllMasterData,
} = masterDataSlice.actions;

export default masterDataSlice.reducer;
