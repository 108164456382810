import { CssBaseline, ThemeProvider } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';
import './i18next';
import Router from './routes/Router';
import { baselightTheme } from './theme/DefaultColors';

import axiosInitialization from './utils/axios';
axiosInitialization();

function App() {
  const routing = useRoutes(Router);
  const theme = baselightTheme;
  return (
    <div style={{ backgroundColor: '#F8F8F8' }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {routing}
        <Toaster position="bottom-left" />
      </ThemeProvider>
    </div>
  );
}

export default App;
